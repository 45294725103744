import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { LanguageContext } from "../context/langContext"

const NewsletterForm = ({ locale }) => (
  <LanguageContext.Consumer>
    {t => (
      <Form>
        <form
          method="post"
          name="newsletter"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <section>
            <input
              type="text"
              name="email"
              id="email"
              placeholder={t.newsletterPlaceholder}
              required
            />

            <input type="submit" value="+" />
          </section>
        </form>
      </Form>
    )}
  </LanguageContext.Consumer>
)
const Form = styled.div`
  form {
    input {
      padding: ${DesignTokens.spacing[4]};
      border: 1px solid ${DesignTokens.colors.tertiary[200]};
      border-radius: 1000px 0 0 1000px;
      height: 52px;
      &:hover,
      &:focus {
        border: 1px solid ${DesignTokens.colors.primary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.white};
      border: 1px solid ${DesignTokens.colors.tertiary[200]};
      border-left: none;
      color: ${DesignTokens.colors.white};
      border-radius: 0 1000px 1000px 0;
      color: ${DesignTokens.colors.black};
      width: 50px;
      margin-left: -1px;
      &:hover {
        color: ${DesignTokens.colors.primary[500]};
        cursor: pointer;
      }
      &:active {
        background: ${DesignTokens.colors.primary[500]};
      }
    }
    section {
      display: flex;
      flex-direction: row;
    }
  }
`

export default NewsletterForm
