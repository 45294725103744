import React from "react"
import { usePrimaryMenu } from "../hooks/usePrimaryMenu"
import {
  ButtonGroup,
  ExternalLinkButtonSecondary,
  ExternalLinkButtonTertiary,
  ExternalLinkButton,
} from "./button"

const MenuCallToAction = ({ locale, theme }) => {
  const lang = locale || "it"
  const primaryMenu = usePrimaryMenu()
  const menuI18n = primaryMenu.filter(obj => {
    return obj.locale === lang
  })
  const demo = menuI18n[0].callToAction[0]
  const login = menuI18n[0].callToAction[1]
  return (
    <ButtonGroup>
      {theme === "light" ? (
        <ExternalLinkButtonSecondary
          href={demo.url}
          target={demo.blank ? "blank" : null}
          rel={demo.blank ? "noopener nofollow" : null}
        >
          {demo.anchorText}
        </ExternalLinkButtonSecondary>
      ) : (
        <ExternalLinkButtonTertiary
          href={demo.url}
          target={demo.blank ? "blank" : null}
          rel={demo.blank ? "noopener nofollow" : null}
        >
          {demo.anchorText}
        </ExternalLinkButtonTertiary>
      )}
      <ExternalLinkButton
        href={login.url}
        target={login.blank ? "blank" : null}
        rel={login.blank ? "noopener nofollow" : null}
    >
        {login.anchorText}
      </ExternalLinkButton>
    </ButtonGroup>
  )
}

export default MenuCallToAction
