const DesignTokens = {
    colors: {
      white: "#ffffff",
      black: "#040111",
      primary: {
        500: "#006699",
      },
      secondary: {
        500: "#040111",
      },
      tertiary: {
        500: "#ffffff",
      },
    },
    borderRadius: ["1rem"],
    spacing: [
      "0rem",
      "0.25rem",
      "0.5rem",
      "0.75rem",
      "1rem",
      "1.25rem",
      "1.5rem",
      "2rem",
      "2.25rem",
      "3rem",
      "4rem",
      "5rem",
    ],
    fontSizes: [
      "0rem",
      "0.5rem",
      "0.75rem",
      "0.875rem",
      "1rem",
      "1.25rem",
      "1.5rem",
      "2rem",
      "2.25rem",
      "3rem",
      "4rem",
      "5rem",
    ],
    elevation: {
      raised:
        "rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px;",
      floating:
        "rgba(46, 41, 51, 0.08) 0px 2px 4px, rgba(71, 63, 79, 0.16) 0px 4px 8px;",
      overlay:
        "rgba(46, 41, 51, 0.08) 0px 4px 8px, rgba(71, 63, 79, 0.16) 0px 8px 16px;",
      dialog:
        "rgba(46, 41, 51, 0.08) 0px 4px 16px, rgba(71, 63, 79, 0.16) 0px 8px 24px;",
    },
  }
  
  export default DesignTokens
  