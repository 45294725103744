import React from "react"
import { Link } from "gatsby"
import { useFooter } from "../hooks/useFooter"
import styled from "styled-components"
import { Heading, Lead } from "./typography"
import DesignTokens from "./designTokens"
import background from "../images/hero-image.jpg"
import facebookIcon from "../images/fb.svg"
import linkedinIcon from "../images/in.svg"
import { Container, ItemsList } from "./utils"
import CallToAction from "./callToAction"
import RichContentBlockText from "./richContentBlockText"
import NewsletterForm from "./newsletterForm"
import I18nLink from "./i18nLink"
import MenuCallToAction from "./menuCallToAction"
import { LanguageContext, languages } from "../context/langContext"
import { Grid, Box } from "theme-ui"
import HubspotForm from "./hubspotForm"

const Footer = ({ locale, minimal }) => {
  const formId = {
    it: "13f88473-a126-4e0b-885a-258bba455ff3",
    en: "70712c27-49de-47d6-b72a-33fd735e07c6",
    es: "677924f5-51be-4b77-9308-362035a39340",
  }
  const lang = locale || "it"
  const footer = useFooter()
  const allFooters = footer.filter(obj => {
    return obj.locale === lang
  })
  const footerI18n = allFooters[0]
  const providerLanguage = languages[locale]

  return (
    <LanguageContext.Provider value={providerLanguage}>
      <Wrapper>
        {!minimal && (
          <Cta>
            <Heading>{footerI18n.title}</Heading>
            <Lead>{footerI18n.subtitle}</Lead>
            {footerI18n.callToAction && (
              <CallToAction cta={footerI18n.callToAction} />
            )}
          </Cta>
        )}
        <InfoWrapper>
          <Info>
            <Grid columns={[1, 2, 2, 2, 4]} as="section">
              <div>
                <LanguageContext.Consumer>
                  {t => <Lead>{t.contacts}</Lead>}
                </LanguageContext.Consumer>
                <RichContentBlockText
                  html={footerI18n.contacts}
                  theme={"dark"}
                />
              </div>
              <div>
                <div>
                  <LanguageContext.Consumer>
                    {t => <Lead>{t.company}</Lead>}
                  </LanguageContext.Consumer>
                  <Links
                    dangerouslySetInnerHTML={{ __html: footerI18n.links }}
                  />
                </div>
                <div>
                  <LanguageContext.Consumer>
                    {t => <Lead>{t.language}</Lead>}
                  </LanguageContext.Consumer>
                  <ItemsList>
                    <li>
                      <Link to="/">Italiano</Link>
                    </li>
                    <li>
                      <Link to="/en">English</Link>
                    </li>
                    <li>
                      <Link to="/es">Español</Link>
                    </li>
                  </ItemsList>
                </div>
              </div>
              <LanguageContext.Consumer>
                {t => (
                  <div>
                    <div>
                      <Lead>{t.followUs}</Lead>
                      <div className="social-icons">
                        <a href="https://www.facebook.com/wendasrl">
                          <img src={facebookIcon} alt="Facebook Page" />
                        </a>
                        <a href="https://www.linkedin.com/company/wenda-srl/">
                          <img src={linkedinIcon} alt="Linkedin Page" />
                        </a>
                      </div>
                    </div>
                    <div>
                      <Lead>{t.newsletter}</Lead>
                      <HubspotForm formId={formId[lang]} />
                    </div>
                  </div>
                )}
              </LanguageContext.Consumer>
              {!minimal && (
                <Box
                  sx={{
                    div: {
                      flexDirection: "column",
                      a: { margin: 0, marginBottom: 3 },
                    },
                  }}
                >
                  <MenuCallToAction locale={lang} />
                </Box>
              )}
            </Grid>
          </Info>
          <Container>
            <Copyright>
              {new Date().getFullYear()} Wenda srl •{" "}
              <I18nLink locale={lang} slug="privacy-policy">
                Privacy policy
              </I18nLink>{" "}
              •{" "}
              <I18nLink locale={lang} slug="cookie-policy">
                Cookie policy
              </I18nLink>{" "}
              •{" "}
              <I18nLink locale={lang} slug="gdpr">
                Privacy policy ridotta
              </I18nLink>
            </Copyright>
          </Container>
        </InfoWrapper>
      </Wrapper>
    </LanguageContext.Provider>
  )
}

const Copyright = styled.div`
  border-top: 1px dashed ${DesignTokens.colors.white};
  padding: 2rem 0;
  a {
    color: ${DesignTokens.colors.white};
  }
`

const Links = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0;
    }
  }
`

const Wrapper = styled.div`
  background-color: ${DesignTokens.colors.secondary[500]};
  background-image: url(${background});
  background-size: cover;
  background-position: top center;
  color: ${DesignTokens.colors.white};
`

const Cta = styled(Container)`
  padding: 6rem 0 4rem;
  & > * {
    margin-bottom: ${DesignTokens.spacing[8]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const InfoWrapper = styled.div`
  background-color: rgba(4, 1, 17, 0.9);
`

const Info = styled(Container)`
  .social-icons {
    a {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
  p {
    margin-bottom: ${DesignTokens.spacing[1]};
  }
  h5 {
    margin-bottom: ${DesignTokens.spacing[7]};
    color: ${DesignTokens.colors.primary[500]};
  }
  padding: 6rem 0 1rem;
  section {
    a {
      color: ${DesignTokens.colors.white};
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[8]};
      &:last-child {
        margin-bottom: 0;
      }
    }
    & > div {
      margin-right: 4rem;
      & > * {
        margin-bottom: ${DesignTokens.spacing[8]};
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-right: 2rem;
      }
    }
  }
`

export default Footer
