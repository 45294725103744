import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () => {
  const footer = useStaticQuery(graphql`
    query FooterQuery {
      allDatoCmsFooter {
        nodes {
          title
          subtitle
          locale
          contacts
          links
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
        }
      }
    }
  `)

  return footer.allDatoCmsFooter.nodes
}
