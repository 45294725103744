import React from "react"
import { getPagePath, getStoryPath } from "../utils/path"
import {
  LinkButton,
  ExternalLinkButton,
  LinkButtonTertiary,
  ExternalLinkButtonTertiary,
} from "./button"

const CallToAction = ({ cta, theme }) => {
  const linkProps = {
    target: cta.blank ? "blank" : null,
    rel: cta.blank ? "noopener nofollow" : null,
  }
  const Link = theme === "dark" ? LinkButtonTertiary : LinkButton
  const ExternalLink =
    theme === "dark" ? ExternalLinkButtonTertiary : ExternalLinkButton

  return cta.page ? (
    <Link
      to={
        cta.page.model.apiKey === "page"
          ? getPagePath(cta.page)
          : getStoryPath(cta.page)
      }
    >
      {cta.anchorText}
    </Link>
  ) : (
    <ExternalLink href={cta.url} {...linkProps}>
      {cta.anchorText}
    </ExternalLink>
  )
}

export default CallToAction
