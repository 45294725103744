import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Box } from "theme-ui"

const HubspotForm = ({ formId }) => {
  useEffect(() => {
    console.log("GORM")
    const script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (
        typeof window !== "undefined" &&
        typeof window.hbspt !== "undefined"
      ) {
        if (window.hbspt.forms !== "undefined") {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "2795690",
            formId: formId,
            target: `#formContainer-${formId}`,
          })
        }
      }
    }, 500)
    return () => window.clearTimeout(timeoutID)
  })
  return <Box sx={{ mt: 2 }} id={`formContainer-${formId}`}></Box>
}

export default HubspotForm
