import { useStaticQuery, graphql } from "gatsby"

export const useMenu = locale => {
  const menu = useStaticQuery(graphql`
    query MenuQuery {
      allDatoCmsPrimaryMenu(
        filter: { root: { eq: true } }
        sort: { fields: position }
      ) {
        nodes {
          anchor
          id
          locale
          root
          position
          page {
            ... on DatoCmsAboutPage {
              slug
              title
              id
              locale
              _allSlugLocales {
                locale
                value
              }
              model {
                apiKey
              }
            }
            ... on DatoCmsPillarContent {
              slug
              title
              id
              locale
              _allSlugLocales {
                locale
                value
              }
              model {
                apiKey
              }
            }
            ... on DatoCmsPage {
              slug
              title
              id
              position
              locale
              _allSlugLocales {
                locale
                value
              }
              model {
                apiKey
              }
            }
          }
          treeChildren {
            anchor
            id
            position
            page {
              ... on DatoCmsAboutPage {
                slug
                title
                id
                locale
                _allSlugLocales {
                  locale
                  value
                }
                model {
                  apiKey
                }
              }
              ... on DatoCmsPage {
                slug
                title
                id
                position
                locale
                _allSlugLocales {
                  locale
                  value
                }
                model {
                  apiKey
                }
              }
              ... on DatoCmsPillarContent {
                slug
                title
                id
                locale
                _allSlugLocales {
                  locale
                  value
                }
                model {
                  apiKey
                }
              }
            }
            locale
            root
          }
        }
      }
    }
  `)

  const i18nMenu = menu.allDatoCmsPrimaryMenu.nodes.filter(
    link => link.locale === locale
  )

  i18nMenu.map(link => {
    if (link.treeChildren.length > 0) {
      link.treeChildren.sort((a, b) => a.position - b.position)
    }
  })

  return i18nMenu
}
