import React, { useState } from "react"
import PageLinks from "./pageLinks"
import { Container } from "./utils"
import logo from "../images/wenda-logo.svg"
import I18nLink from "../components/i18nLink"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import MenuCallToAction from "./menuCallToAction"
import { Flex, Box } from "theme-ui"
import { Link } from "gatsby"

const Nav = ({ locale, theme, landing }) => {
  const [show, setShow] = useState(false)
  return (
    <CustomContainer>
      <I18nLink locale={locale}>
        <img src={logo} alt="Wenda Logo" />
      </I18nLink>
      <LinksWrapper theme={theme}>
        <PageLinks locale={locale} />
        {!landing && <MenuCallToAction theme={theme} locale={locale} />}
        <Box
          sx={{ position: "relative", paddingY: [1, 1, 3, 3] }}
          onMouseEnter={() => setShow(!show)}
          onMouseLeave={() => setShow(!show)}
        >
          <Box>
            {locale
              .toLowerCase()
              .replace(/\w/, firstLetter => firstLetter.toUpperCase())}
          </Box>
          <Box
            sx={{
              display: show ? "block" : "none",
              position: "absolute",
              right: 0,
              backgroundColor: "red",
            }}
          >
            <Flex
              as="ul"
              sx={{
                display: show ? "flex" : "none",
                flexDirection: "column",
                listStyle: "none",
                padding: 2,
                margin: 0,
                backgroundColor: ["dark", "dark", "light", "light"],
                position: ["absolute"],
                textAlign: ["right"],
                right: 0,
                top: [10],
                width: "max-content",
                boxShadow:
                  "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
                borderRadius: "sm",
                a: {
                  color: "dark",
                },
              }}
            >
              <Box as="li" sx={{ padding: 1 }}>
                <Link to="/">Italiano</Link>
              </Box>
              <Box as="li" sx={{ padding: 1 }}>
                <Link to="/en">English</Link>
              </Box>
              <Box as="li" sx={{ padding: 1 }}>
                <Link to="/es">Español</Link>
              </Box>
            </Flex>
          </Box>
        </Box>
      </LinksWrapper>
    </CustomContainer>
  )
}

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${DesignTokens.spacing[6]} 0;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 2rem;
    max-width: 100%;
  }
  @media screen and (min-width: 1025px) and (max-width: 1420px) {
    margin: 0 2rem;
    max-width: 100%;
  }
  @media screen and (min-width: 1421px) {
    margin: 0 auto;
    max-width: 90%;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > ul {
    display: flex;
    flex-direction: row;
    & > li {
      margin-right: ${DesignTokens.spacing[6]};
      &:last-child {
        margin-right: 0;
      }
    }
  }
  a {
    color: ${props =>
      props.theme === "light"
        ? DesignTokens.colors.secondary[500]
        : DesignTokens.colors.white};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  & > * {
    margin-right: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-right: 0;
    }
  }
`

export default Nav
