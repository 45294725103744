import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import PageLinks from "./pageLinks"
import { X, Menu } from "react-feather"
import logo from "../images/wenda-logo.svg"
import I18nLink from "../components/i18nLink"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import MenuCallToAction from "./menuCallToAction"
import { Flex } from "@theme-ui/components"
import { Link } from "gatsby"

const MobileNav = ({ locale, theme }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <nav>
      <StyledMenu>
        <I18nLink locale={locale}>
          <img src={logo} alt="Wenda Logo" />
        </I18nLink>
        <div>
          <Button
            role="button"
            tabIndex={0}
            onClick={() => setShowMenu(!showMenu)}
            onKeyDown={() => setShowMenu(!showMenu)}
          >
            <Menu />
          </Button>
        </div>
      </StyledMenu>
      <AnimatePresence>
        {showMenu && (
          <StyledOverlay
            initial={{ opacity: 0, scale: 1.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.15 }}
            exit={{ opacity: 0 }}
          >
            <header>
              <X size={32} onClick={() => setShowMenu(false)} />
            </header>
            <LinksWrapper theme={theme}>
              <PageLinks locale={locale} />
              <MenuCallToAction theme={theme} locale={locale} />
              <Flex
                sx={{
                  justifyContent: "row",
                  mt: 4,
                  "*": {
                    marginX: 3,
                  },
                  a: {
                    color: "white",
                    textDecoration: "none",
                  },
                }}
              >
                <Link to="/">It</Link>
                <Link to="/en">En</Link>
                <Link to="/es">Es</Link>
              </Flex>
            </LinksWrapper>
          </StyledOverlay>
        )}
      </AnimatePresence>
    </nav>
  )
}

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > ul {
    display: flex;
    flex-direction: column;
    margin: 0 0 ${DesignTokens.spacing[4]} 0;
    text-align: center;
    & > li {
      margin: ${DesignTokens.spacing[3]} 0;
      a {
        color: ${DesignTokens.colors.white};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  & > * {
    margin-right: ${DesignTokens.spacing[8]};
    &:last-child {
      margin-right: 0;
    }
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70px;
`

const StyledMenu = styled.div`
  padding: 0 0 0 ${DesignTokens.spacing[3]};
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    height: 100%;
  }
  img {
    width: 120px;
  }
`

const StyledOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.black};
  z-index: 10;
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    text-align: right;
    padding: 0 1.2rem;
  }
  & > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & > li {
      font-size: ${DesignTokens.fontSizes[7]};
      padding: ${DesignTokens.spacing[3]};
      text-align: center;
      margin: 0;
      & > a {
        font-family: "Yantramanav";
        color: ${DesignTokens.colors.white};
        text-decoration: none;
      }
    }
  }
`

export default MobileNav
