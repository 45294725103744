const defaultLocale = "it"

export function getPagePath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "" : `/${pageLocale}`
  let path = `/${
    page._allSlugLocales.find(x => x.locale === pageLocale).value
  }/`
  return lang + path
}

export function getStoryPath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "" : `/${pageLocale}`
  let path = `/stories/${
    page._allSlugLocales.find(x => x.locale === pageLocale).value
  }/`
  return lang + path
}

export function getFocusPath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "" : `/${pageLocale}`
  let path = page._allSlugLocales.find(x => x.locale === pageLocale)
    ? `/focus/${page._allSlugLocales.find(x => x.locale === pageLocale).value}/`
    : null
  return lang + path
}

export function getNewsPostPath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "" : `/${pageLocale}`
  let path = `/news/${
    page._allSlugLocales.find(x => x.locale === pageLocale).value
  }/`
  return lang + path
}

export function getBlogPostPath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "" : `/${pageLocale}`
  let path = `/blog/${
    page._allSlugLocales.find(x => x.locale === pageLocale).value
  }/`
  return lang + path
}

export function getHomePath(locale) {
  return locale === defaultLocale ? "/" : `/${locale}/`
}

export function getBlogPath(locale) {
  switch (locale) {
    case "en":
      return `/en/blog/`
    case "es":
      return `/es/blog/`
    default:
      return `https://blog.wenda-it.com/it-it`
  }
}

export function getAboutPath(locale) {
  return locale === defaultLocale ? `/about/` : `/${locale}/about/`
}

export function getStoriesPath(locale) {
  return locale === defaultLocale ? `/stories/` : `/${locale}/stories/`
}

export function getNewsPath(locale) {
  return locale === defaultLocale ? `/news/` : `/${locale}/news/`
}

export function getArticlePath(page, locale) {
  return locale === defaultLocale
    ? `/blog/${page._allSlugLocales.find(x => x.locale === locale).value}/`
    : `/${locale}/blog/${
        page._allSlugLocales.find(x => x.locale === locale).value
      }/`
}
