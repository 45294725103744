import styled from "styled-components"
import DesignTokens from "./designTokens"

const Display = styled.h1`
  font-size: ${DesignTokens.fontSizes[11]};
  font-weight: 800;
  line-height: 1;
  color: ${props => props.color || "inherit"};
  @media screen and (max-width: 767px) {
    font-size: ${DesignTokens.fontSizes[9]};
  }
`
const DisplaySmall = styled.h2`
  font-size: ${DesignTokens.fontSizes[9]};
  font-weight: 800;
  color: ${props => props.color || "inherit"};
`
const Heading = styled.h3`
  font-size: ${DesignTokens.fontSizes[8]};
  font-weight: 700;
  color: ${props => props.color || "inherit"};
`
const HeadingSmall = styled.h4`
  font-size: ${DesignTokens.fontSizes[6]};
  font-weight: 500;
  line-height: 1.1;
  color: ${props => props.color || "inherit"};
`
const Lead = styled.h5`
  color: ${props => props.color};
  font-size: ${DesignTokens.fontSizes[5]};
  font-weight: 700;
  line-height: 1.4;
`
const Body = styled.p`
  font-size: ${DesignTokens.fontSizes[4]};
  font-weight: 500;
  color: ${props => props.color || "inherit"};
`
const BodySmall = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  font-weight: 500;
  color: ${props => props.color || "inherit"};
`
const Caption = styled.p`
  font-size: ${DesignTokens.fontSizes[4]};
  font-weight: 500;
  color: ${props => props.color || "inherit"};
`
const CaptionSmall = styled.p``

export {
  Display,
  DisplaySmall,
  Heading,
  HeadingSmall,
  Lead,
  Body,
  BodySmall,
  Caption,
  CaptionSmall,
}
