import { useStaticQuery, graphql } from "gatsby"

export const usePrimaryMenu = () => {
  const menu = useStaticQuery(graphql`
    query ContactModeQuery {
      allDatoCmsMenu(filter: { name: { eq: "primary" } }) {
        nodes {
          id
          locale
          links {
            ... on DatoCmsAboutPage {
              id
              slug
              title
            }
            ... on DatoCmsPage {
              id
              slug
              title
            }
          }
          callToAction {
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
        }
      }
    }
  `)

  return menu.allDatoCmsMenu.nodes
}
