import React from "react"
import { ItemsList } from "./utils"
import { Box } from "theme-ui"
import {
  getAboutPath,
  getBlogPath,
  getFocusPath,
  getPagePath,
} from "../utils/path"
import { Link } from "gatsby"
import I18nLink from "./i18nLink"
import { useMenu } from "../hooks/useMenu"
import DropDownMenu from "./dropDownMenu"

const PageLinks = ({ locale }) => {
  const lang = locale || "it"
  const menuI18n = useMenu(locale)

  function renderSwitch(page) {
    switch (page.model.apiKey) {
      case "page":
        return getPagePath(page)
      case "pillar_content":
        return getFocusPath(page)
      default:
        return getAboutPath(lang)
    }
  }

  return (
    <ItemsList>
      {menuI18n.map(link => (
        <>
          {link.anchor && (
            <Box as="li" sx={{ position: "relative" }} key={link.id}>
              {link.treeChildren.length > 0 ? (
                <DropDownMenu link={link} />
              ) : (
                <Box sx={{ paddingY: [1, 1, 3, 3] }}>
                  {link.page ? (
                    <Link to={renderSwitch(link.page)}>{link.anchor}</Link>
                  ) : (
                    <Box>{link.anchor}</Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </>
      ))}
      <Box as="li" sx={{ paddingY: [1, 1, 3, 3] }}>
        <I18nLink locale={lang} slug="stories">
          Stories
        </I18nLink>
      </Box>
      <Box as="li" sx={{ paddingY: [1, 1, 3, 3] }}>
        <Link to={getBlogPath(lang)}>Blog</Link>
      </Box>
    </ItemsList>
  )
}

export default PageLinks
