import React from "react"
import { Link } from "gatsby"

const I18nLink = ({ locale, slug, children }) => {
  return locale === "it" ? (
    <Link to={"/" + (slug || "")}>{children}</Link>
  ) : (
    <Link to={"/" + locale + "/" + (slug || "")}>{children}</Link>
  )
}

export default I18nLink
