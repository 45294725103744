import React from "react"
import styled from "styled-components"
import parse, { domToReact } from "html-react-parser"
import { Body, Caption, Lead, HeadingSmall, Display } from "./typography"
import DesignTokens from "./designTokens"
import Blockquote from "./blockquote"

const RichContentBlockText = ({ html, theme }) => {
  const color =
    theme === "dark" ? DesignTokens.colors.white : DesignTokens.colors.black
  const options = {
    replace: ({ name, children }) => {
      switch (name) {
        case "h1":
          return <Display>{domToReact(children)}</Display>
        case "p":
          return <CustomBody color={color}>{domToReact(children)}</CustomBody>
        case "h4":
          return (
            <HeadingSmall color={color} as="p">
              {domToReact(children)}
            </HeadingSmall>
          )
        case "h5":
          return <Lead as="p">{domToReact(children)}</Lead>
        case "h6":
          return <Caption as="p">{domToReact(children)}</Caption>
        case "ul":
          return <List theme={theme}>{domToReact(children)}</List>
        case "blockquote":
          return <Blockquote>{domToReact(children)}</Blockquote>
      }
    },
  }
  return <Wrapper>{parse(html, options)}</Wrapper>
}

const List = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding-top: ${DesignTokens.spacing[5]};
  border-top: 1px dashed
    ${props =>
      props.theme === "dark"
        ? DesignTokens.colors.white
        : DesignTokens.colors.black};
  & > li {
    padding-left: 1em;
    text-indent: -1em;
    margin: 0;
    &:before {
      content: "//";
      padding-right: 5px;
    }
    a {
      color: ${props =>
        props.theme === "dark"
          ? DesignTokens.colors.white
          : DesignTokens.colors.black};
    }
  }
`

const Wrapper = styled.div`
  & > * {
    margin-bottom: ${DesignTokens.spacing[6]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomBody = styled(Body)`
  /* margin-bottom: ${DesignTokens.spacing[6]}; */
`

export default RichContentBlockText
