import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import loadable from '@loadable/component'
import { LanguageContext, languages } from "../context/langContext"
import Footer from "./footer"
import Hreflang from "./hreflang"

const ScrollTracker = loadable(() => import('./scrollTracker'), { ssr: false })

const Layout = ({ children, locale, minimalFooter, i18nPaths }) => {
  const data = useStaticQuery(graphql`
    query LayoutSiteQuery {
      gatsbySite: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  return (
    <LanguageContext.Provider value={languages[locale]}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {i18nPaths && (
        <Hreflang
          paths={i18nPaths}
          siteUrl={data.gatsbySite.siteMetadata.siteUrl}
        />
      )}
      {children}
      <Footer locale={locale} minimal={minimalFooter} />
      <ScrollTracker />
    </LanguageContext.Provider>
  )
}

export default Layout
