import styled from "styled-components"
import DesignTokens from "./designTokens"

const Blockquote = styled.blockquote`
  border-top: 1px dashed ${DesignTokens.colors.primary[500]};
  border-bottom: 1px dashed ${DesignTokens.colors.primary[500]};
  margin: 0 5rem;
  padding: ${DesignTokens.spacing[4]} 0 ${DesignTokens.spacing[7]};
  @media screen and (max-width: 767px) {
    margin: 0;
  }
  p {
    font-size: ${DesignTokens.fontSizes[5]};
    font-weight: 700;
    line-height: 1.1;
    color: ${DesignTokens.colors.primary[500]};
  }
`

export default Blockquote
