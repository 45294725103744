import React from "react"
import { Box } from "theme-ui"
import { getAboutPath, getFocusPath, getPagePath } from "../utils/path"
import { Link } from "gatsby"
import { useState } from "react"

const DropDownMenu = ({ link }) => {
  const [show, setShow] = useState(false)

  function renderSwitch(page) {
    console.log(page)
    switch (page.model.apiKey) {
      case "page":
        return getPagePath(page)
      case "pillar_content":
        return getFocusPath(page)
      default:
        return getAboutPath(page.locale)
    }
  }

  return (
    <Box
      sx={{ paddingY: [1, 1, 3, 3] }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onTap={() => setShow(!show)}
    >
      <Box>
        {link.page ? (
          <Link to={renderSwitch(link.page)}>{link.anchor}</Link>
        ) : (
          <Box>{link.anchor}</Box>
        )}
      </Box>
      {link.treeChildren.length > 0 && (
        <Box
          as="ul"
          sx={{
            display: show ? "flex" : "none",
            flexDirection: "column",
            listStyle: "none",
            padding: 2,
            margin: 0,
            backgroundColor: ["dark", "dark", "light", "light"],
            position: ["relative", "relative", "absolute", "absolute"],
            textAlign: ["center", "center", "left", "left"],
            top: [10, 10, 50, 50],
            width: "max-content",
            boxShadow:
              "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
            borderRadius: "sm",
            a: {
              color: "dark",
            },
          }}
        >
          {link.treeChildren.map(link => (
            <Box as="li" sx={{ padding: 1 }} key={link.id}>
              {link.page && (
                <Link to={renderSwitch(link.page)}>{link.anchor}</Link>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default DropDownMenu
