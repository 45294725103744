import React from "react"

export const languages = {
  en: {
    locale: "en",
    newsletter: "Subscribe to the newsletter",
    newsletterPlaceholder: "email address",
    followUs: "Follow us",
    company: "Company",
    contacts: "Contacts",
    language: "Language",
    discoverMore: "Discover more +",
    downloadPdf: "Download pdf",
    market: "Market",
    noArticles: "No articles published yet",
    watchVideo: "Watch video",
    news: "News",
    relatedArticles: "Related articles",
  },
  es: {
    locale: "es",
    newsletter: "Suscríbete al boletín",
    newsletterPlaceholder: "dirección de correo electrónico",
    followUs: "Síguenos",
    company: "Empresa",
    contacts: "Contactos",
    language: "Idioma",
    discoverMore: "Descubre más +",
    downloadPdf: "Descargar pdf",
    market: "Mercado",
    noArticles: "No hay artículos publicados aún",
    watchVideo: "Ver vídeo",
    news: "Noticias",
    relatedArticles: "Artículos relacionados",
  },
  it: {
    locale: "it",
    newsletter: "Iscriviti alla newsletter",
    newsletterPlaceholder: "indirizzo email",
    followUs: "Seguici su",
    company: "Azienda",
    contacts: "Contatti",
    language: "Lingua",
    discoverMore: "Scopri di più +",
    downloadPdf: "Scarica pdf",
    market: "Mercato",
    noArticles: "Nessun articolo pubblicato",
    watchVideo: "Guarda video",
    news: "Notizie",
    relatedArticles: "Articoli correlati",
  },
}

export const LanguageContext = React.createContext(languages.en)
